import Head from "next/head";
import dynamic from "next/dynamic";
import { ConfigProvider } from "antd";
import { AuthProvider } from "../middlewares/userAuth";
import default_vi_VN from "antd/lib/locale/vi_VN";
import "antd/dist/antd.css";
import "../../assets/global.css";
import "../../assets/layout.css";
import "../../assets/parameter.css";
import "../../assets/admin.css";
import "../../assets/functionpack.css";
import "../../assets/useful.css";
import "../../assets/overrides/overrides.css";
import "../../assets/ui/ui.css";
import "../../assets/pages/pages.css";
import "../../assets/customer.css";
import "../../assets/components/index.css";
import "../../assets/montserrat.css";
import "../../assets/frontend/style.css";
import "react-quill/dist/quill.snow.css";

const App = ({ Component, pageProps, router }) => {
  const adminPanel = router.route.startsWith("/admin") ? true : false;
  const getLayout = Component.getLayout || ((page) => page);
  const infoLocal =
    localStorage.getItem("info") && localStorage.getItem("info") !== "null"
      ? JSON.parse(localStorage.getItem("info"))
      : {};
  return adminPanel ? (
    <>
      <AuthProvider>
        <ConfigProvider locale={default_vi_VN}>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta charSet="utf-8" />
            <title>Admin - {infoLocal.name}</title>
          </Head>
          {getLayout(<Component {...pageProps} />)}
        </ConfigProvider>
      </AuthProvider>
    </>
  ) : (
    getLayout(
      <ConfigProvider locale={default_vi_VN}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta charSet="utf-8" />
          <title>{infoLocal.name}</title>
        </Head>
        <Component {...pageProps} />
      </ConfigProvider>
    )
  );
};

export default dynamic(() => Promise.resolve(App), {
  ssr: false,
});
