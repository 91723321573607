import getConfig from "next/config";
import qs from "query-string";
import axios from "axios";

const { publicRuntimeConfig } = getConfig();

export const fetchWrapper = {
  get,
  post,
  put,
  putWithoutId,
  getCustomerInfo,
  delete: _delete,
};

async function get(model, query) {
  try {
    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
      ...authHeader(model),
    };

    const params = { ...query };

    const rs = await axios
      .get(`${publicRuntimeConfig.apiUrl}${model}`, {
        headers,
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "brackets" });
        },
      })
      .catch(function(error) {
        if (error.response && error.response.status === 308) {
          localStorage.removeItem("user");
          window.location.replace(`${window.location.origin}`);
        }
      });
    return rs;
  } catch (error) {
    handleResponseError(error);
  }
}

async function post(model, body, options = {}) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        ...authHeader(model),
      },
      credentials: "include",
    };
    const rs = await axios
      .post(`${publicRuntimeConfig.apiUrl}${model}`, body, {
        headers: requestOptions.headers,
        ...options,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "brackets" });
        },
      })
      .catch(function(error) {
        if (error.response && error.response.status === 308) {
          localStorage.removeItem("user");
          window.location.replace(`${window.location.origin}`);
        }
      });
    return rs;
  } catch (error) {
    handleResponseError(error);
  }
}

async function put(model, id, body) {
  try {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        ...authHeader(model),
      },
      credentials: "include",
    };

    let url;
    if (id) {
      url = `${publicRuntimeConfig.apiUrl}${model}/${id}`;
    } else {
      url = `${publicRuntimeConfig.apiUrl}${model}`;
    }

    const rs = await axios
      .put(url, body, {
        headers: requestOptions.headers,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "brackets" });
        },
      })
      .catch(function(error) {
        if (error.response && error.response.status === 308) {
          localStorage.removeItem("user");
          window.location.replace(`${window.location.origin}`);
        }
      });
    return rs;
  } catch (error) {
    handleResponseError(error);
  }
}

function putWithoutId(model, body) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        ...authHeader(model),
      },
      credentials: "include",
    };

    const rs = axios.put(`${publicRuntimeConfig.apiUrl}${model}`, body, {
      headers: requestOptions.headers,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    });
    return rs;
  } catch (error) {
    handleResponseError(error);
  }
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(model, id) {
  try {
    const headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
      ...authHeader(model),
    };

    const rs = axios.delete(`${publicRuntimeConfig.apiUrl}${model}/${id}`, {
      headers: headers,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    });
    return rs;
  } catch (error) {
    handleResponseError(error);
  }
}

async function getCustomerInfo(reloadCache = false) {
  const infoLocal =
    localStorage.getItem("info") && localStorage.getItem("info") !== "null"
      ? JSON.parse(localStorage.getItem("info"))
      : {};

  if (!reloadCache && Object.keys(infoLocal).length !== 0) {
    return infoLocal;
  }

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  };
  const info = await axios.get(
    `${publicRuntimeConfig.apiUrl}publish/customer/info`,
    {
      headers: requestOptions.headers,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    }
  );
  localStorage.setItem("info", JSON.stringify(info.data.data));
  return info.data.data;
}

// helper functions

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = localStorage.getItem("token");
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
}

function handleResponseError(response) {
  if (response?.response?.status === 401) {
    localStorage.removeItem("user");
    window.location.replace(`${window.location.origin}`);
  }
}
