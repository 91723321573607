import { BehaviorSubject } from 'rxjs';
import getConfig from 'next/config';
// import Router from 'next/router';
// import fetchWrapper from './fetch-wrapper';

const { publicRuntimeConfig } = getConfig();
const baseUrl = `${publicRuntimeConfig.apiUrl}/users`;
const userSubject = new BehaviorSubject(
    process.browser && JSON.parse(localStorage.getItem('user'))
);

export const userService = {
    user: userSubject.asObservable(),
    get userValue() {
        return userSubject.value;
    }
};
