import {
  useEffect,
  useState,
  useContext,
  createContext,
  useCallback,
} from "react";
import Router from "next/router";
import { userService } from "../service";

const authContext = createContext();

function useProvideAuth() {
  const [isAuthed, setIsAuthed] = useState(false);

  useEffect(() => {
    if (
      !userService ||
      (userService && !userService.userValue) ||
      (userService && userService.userValue && !userService.userValue.token)
    ) {
      if (Router.router.state.pathname !== "/admin/auth/register") {
        setIsAuthed(false);
        Router.push("/");
      }
    }
    setIsAuthed(true);
    // Router.push('/');
  }, []);

  return { isAuthed };
}

export const AuthProvider = ({ children }) => {
  const value = useProvideAuth();
  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

export default function useAuth() {
  return useContext(authContext);
}
